import {useEffect, useState} from "react";

function BuyButton({ data, text, handleBuyClick, incomesData}) {
    return (
        <button onClick={() => handleBuyClick(data, incomesData)}>
            {text}
        </button>
    );
}
function BuyComponent({ data, user, tg, pan, onStateBuyChange, incomesData, sli, symbol, balanceUpdater}) {
    function formatPrice(price) {
        if (price >= 1000) {
            return (price / 1000).toFixed(1) + 'к'; // Сокращение до 'к'
        }
        return +price.toFixed(5);
    }

    function getInitialVolumeAndStep(price) {
        let initialVolume = 0.1;
        let step = 0.1;
        if (price < 0.0001) {
            initialVolume = 10000000;
            step = 10000000;
        } else if (price < 0.01) {
            initialVolume = 100000;
            step = 100000;
        } else if (price < 1) {
            initialVolume = 1000;
            step = 1000;
        } else if (price < 10) {
            initialVolume = 100;
            step = 100;
        } else if (price >= 10 && price < 100) {
            initialVolume = 10;
            step = 10;
        } else if (price >= 100 && price < 1000) {
            initialVolume = 1;
            step = 1;
        }
        return {initialVolume, step};
    }
    const [selectedValue, setSelectedValue] = useState(0);
    const [step, setStep] = useState(0);
    const [politic, setPolitic] = useState(true);
    const [series, setSeries] = useState([{ data: [] }]);

    // Function to convert the UNIX timestamp to a more readable format
    function convertTimestamp(unixTimestamp) {
        return new Date(unixTimestamp * 1000);
    }

    // Fetch the data from the API
    async function fetchData(symbol) {
        // Проверяем, что symbol определён
        if (!symbol || symbol.trim() === "") {
            console.error("Symbol is not defined or empty");
            return;
        }

        try {
            const response = await fetch(`https://api.spin2win.tech/GetKlines?symbol=${symbol}`, {
                headers: {
                    'Check-String': window.Telegram.WebApp.initData ? window.Telegram.WebApp.initData : ""
                }});
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const data = await response.json();

            const seriesData = Object.keys(data).map(timestamp => {
                const { open, high, low, close } = data[timestamp];
                return {
                    x: convertTimestamp(timestamp),
                    y: [open, high, low, close].map(Number)
                };
            });

            setSeries([{ data: seriesData }]);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        // Проверяем, что symbol определён
        if (symbol) {
            fetchData(symbol);
        }
    }, [symbol]);  // fetchData будет вызываться только при изменении symbol

    useEffect(() => {
        const {initialVolume, step} = getInitialVolumeAndStep(data.cost);
        setSelectedValue(initialVolume);
        setStep(step);
    }, [data.cost]);

    const options = {
        chart: {
            toolbar: {
                show: false,
            },
            type: 'candlestick',
            height: 350
        },
        title: {
            text: `${symbol} Candlestick Chart`, // Динамически обновляемый заголовок
            align: 'right'
        },
        xaxis: {
            type: 'datetime'
        },
        yaxis: {
            tooltip: {
                enabled: true
            }
        },
        // theme: {
        //     mode: 'dark', 
        //     palette: 'palette1', 
        //     monochrome: {
        //         enabled: true,
        //         color: '#255aee',
        //         shadeTo: 'light',
        //         shadeIntensity: 0.65
        //     },
        // }
    };

    return (
            <div className="buy_overlay" id="buy">
                {sli===1 ? (
                        <>
                <div id="buy_segment_1">
                        <img src={data.img_url} alt={data.title} id="buy_segment_1_img"/>
                        <div id="buy_segment_1_div">
                            <p style={{color: 'white', fontSize: '100%'}}>Вы владеете</p>
                            <p style={{color: 'white', fontSize: '80%'}}>{incomesData ? (incomesData[0]).toFixed(1) : "0"}</p>
                            <p style={{color: 'white', fontSize: '100%'}}>Заработок</p>
                            <p style={{color: 'white', fontSize: '70%'}}>в час</p>
                            <p style={{color: 'white', fontSize: '80%'}}>{incomesData ? +(incomesData[1] * incomesData[0] * 0.005).toFixed(2) : "0"}</p>
                        </div>
                    </div>
                    <hr/>
                    <div id="buy_segment_1">
                    <div id="buy_segment_1_div">
                        <div>
                            <p style={{color: 'white', fontSize: '100%'}}>Цена</p>
                            <p style={{color: 'white', fontSize: '80%'}}>за лот</p>
                            <div style={{display:'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}><p style={{color: '#EA8C00', fontSize: '100%'}}>{formatPrice(data.cost)}</p><img className="buy_coin"  src="/img/Moneta3%201.png" srcSet="/img/Moneta3 1_2x.png 2x" alt="coin"/></div>
                        </div>

                        </div>
                        <div id="buy_segment_1_div">
                        <div>
                            <p style={{color: 'white', fontSize: '100%'}}>Сумма</p>
                            <p style={{color: 'white', fontSize: '80%'}}>покупки</p>
                            <div style={{display:'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}><p style={{color: '#EA8C00', fontSize: '100%'}}>{!isNaN(selectedValue) ? formatPrice(+(data.cost * selectedValue).toFixed(1)) : "0"}</p><img className="buy_coin"  src="/img/Moneta3%201.png" srcSet="/img/Moneta3 1_2x.png 2x" alt="coin"/></div>
                        </div>

                        </div>

                    </div>
                    <hr/>

                    <div id="buy_segment_form">
                        <div className="active-selecter">
                            <div className="minus-btn"
                                 onClick={() => setSelectedValue(selectedValue - step > 0 ? selectedValue - step : 0)}>-
                            </div>
                            {+selectedValue.toFixed(1)}
                            <div className="plus-btn" onClick={() => setSelectedValue(+(selectedValue + step).toFixed(1))}>+</div>
                        </div>
                        <div id="buy-btn" onClick={async (event) => {
                            if (politic) {
                                event.target.disabled = true;
                                setTimeout(() => {
                                    event.target.disabled = false;
                                }, 1000);
                                const req = await fetch(`https://api.spin2win.tech/BuyIncome/${user._id}/${data._id}?q=${(selectedValue*100).toFixed(0)}`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Check-String': window.Telegram.WebApp.initData ? window.Telegram.WebApp.initData : ""
                                }
                            });
                            if (!req.ok) {
                                if (req.status === 403){
                                    alert(`Покупка не была совершена: недостаточно средств`)
                                } else {
                                    alert("Возникла ошибка, подождите либо обратитесь в поддержку")
                                }
                            }else{
                                onStateBuyChange();
                                alert("Покупка успешна завершена");
                                balanceUpdater(Math.random()*100);
                            }
                            return await req.json();
                        }
                        }}>
                        Купить
                    </div>
                    </div>
                    <hr/>
                    <div id="buy_segment_5">
                        <input type="checkbox" id="buy_checkmark" onClick={() => setPolitic(!politic)} checked={politic}/>
                        <p style={{color: 'white', fontSize: '60%', textAlign: 'center', padding: '5%'}}>Покупая данную ИГРОВУЮ валюту, осознаю, что она не имеет ничего общего с действительной криптовалютой</p>
                    </div>

            </>
                    ) : (
                        <>
                        <div id="buy_segment_1">
                    <img src={data.img_url} alt={data.title} id="buy_segment_1_img"/>
                            <div id="buy_segment_1_div">
                                <p style={{color: 'white', fontSize: '100%'}}>Вы владеете</p>
                                <p style={{color: 'white', fontSize: '80%'}}>{incomesData ? (incomesData[0]).toFixed(1) : "0"}</p>
                                <p style={{color: 'white', fontSize: '100%'}}>Заработок</p>
                                <p style={{color: 'white', fontSize: '70%'}}>в час</p>
                                <p style={{color: 'white', fontSize: '80%'}}>{incomesData ? +(incomesData[1] * incomesData[0] * 0.005).toFixed(2) : "0"}</p>
                            </div>
                        </div>
                            <hr/>
                            <div id="buy_segment_1">
                                <div id="buy_segment_1_div">
                                    <div>
                                        <p style={{color: 'white', fontSize: '100%'}}>Цена</p>
                                        <p style={{color: 'white', fontSize: '80%'}}>за лот</p>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            width: '100%'
                                        }}><p style={{color: '#EA8C00', fontSize: '100%'}}>{formatPrice(data.cost)}</p>
                                            <img className="buy_coin" src="/img/Moneta3%201.png"
                                                 srcSet="/img/Moneta3 1_2x.png 2x" alt="coin"/></div>
                    </div>

                    </div>
                    <div id="buy_segment_1_div">
                    <div>
                        <p style={{color: 'white', fontSize: '100%'}}>Сумма</p>
                        <p style={{color: 'white', fontSize: '80%'}}>продажи</p>
                        <div style={{display:'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}><p style={{color: '#EA8C00', fontSize: '100%'}}>{!isNaN(selectedValue) ? formatPrice(+(data.cost * selectedValue).toFixed(1)) : "0"}</p><img className="buy_coin"  src="/img/Moneta3%201.png" srcSet="/img/Moneta3 1_2x.png 2x" alt="coin"/></div>
                    </div>

                    </div>

                </div>
                <hr/>

                <div id="buy_segment_form">
                    <div className="active-selecter">
                        <div className="minus-btn"
                             onClick={() => setSelectedValue(selectedValue - step > 0 ? selectedValue - step : 0)}>-
                        </div>
                        {+selectedValue.toFixed(1)}
                        <div className="plus-btn"
                             onClick={() => setSelectedValue(+(selectedValue + step).toFixed(1))}>+
                        </div>
                    </div>
                    <div id="buy-btn" onClick={async () => {
                        if (politic) {
                            const req = await fetch(`https://api.spin2win.tech/SellIncome/${user._id}/${data._id}?q=${(selectedValue*100).toFixed(0)}`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Check-String': window.Telegram.WebApp.initData ? window.Telegram.WebApp.initData : ""
                            }
                        });
                        if (!req.ok) {

                            alert("Недостаточно валюты")

                        }else{
                            onStateBuyChange();
                            alert("Продажа успешна завершена");
                            balanceUpdater(Math.random()*100);
                        }
                        return await req.json();
                    }
                    }}>
                    Продать
                </div>
                </div>
                <hr/>
                <div id="buy_segment_5">
                    <input type="checkbox" id="buy_checkmark" onClick={() => setPolitic(!politic)} checked={politic}/>
                    <p style={{color: 'white', fontSize: '60%', textAlign: 'center', padding: '5%'}}>Покупая данную ИГРОВУЮ валюту, осознаю, что она не имеет ничего общего с действительной криптовалютой</p>
                </div>
                    </>
                    )}
            </div>
    );
}

function Earnings({data, tg, balanceUpdater}) {
    const [req_info, setReq_info] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeGroup, setActiveGroup] = useState("");
    const [activeOwnership, setActiveOwnership] = useState("Все");
    const [groups, setGroups] = useState(new Set());
    const [buyComponentData, setBuyComponentData] = useState(null); // State to store selected BuyComponent data
    const [incomesData, setIncomesData] = useState({}); // State to store selected BuyComponent data

    useEffect(() => {
        async function getIncomes() {
            try {
                const req = await fetch('https://api.spin2win.tech/GetIncomes', {
                    method: 'GET',
                    headers: {
                        'Check-String': window.Telegram.WebApp.initData ? window.Telegram.WebApp.initData : ""
                    }
                })
                if (req.ok) {
                    const res = await req.json();

                    setReq_info(res);
                    const uniqueGroups = new Set(res.map(item => item.group));
                    setGroups(uniqueGroups);
                } else {
                    console.error("Failed to fetch data");
                    setReq_info([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setReq_info([]);
            } finally {
                setLoading(false);
            }
        }

        getIncomes();
    }, []);

    // Handle opening the BuyComponent with the relevant data
    const handleBuyClick = (data) => {
        setBuyComponentData(data);
    };
    const handleBuyStateChange = () => {
        getMyIncomes();
    }
    const [slide, setSlide] = useState(1);
    // Handle closing the BuyComponent
    const handleCloseBuyComponent = () => {
        setBuyComponentData(null);
    };


    async function getMyIncomes() {
        let request = await fetch(`https://api.spin2win.tech/GetMyIncomes/${tg.initDataUnsafe?.user?.id}`, {
            method: 'get',
            headers: {
                'Check-String': window.Telegram.WebApp.initData ? window.Telegram.WebApp.initData : ""
            }
        });
        if (!request.ok) {
            console.log("Ошибка при получении доходов", await request.json());
            return
        }
        let dataf = await request.json();
        setIncomesData(dataf.items);
        document.getElementById("invested").innerHTML = dataf.invested.toFixed(2);
        document.getElementById("incomes").innerHTML = dataf.incomes.toFixed(2);
    }
    useEffect( () => {
        getMyIncomes();
    }, []);

    useEffect(() => {
        let el = document.getElementById("scroll-content-address");
        el.scrollTop = 0;
    }, [activeGroup]);

    return (
        <div className="layout_earnings">
            <div className="lay_earnings">
                <div className="earn-men">
                    <div className="attached">
                        <div className="att_up">
                            <span>Вложено</span>
                            <img src="img/Moneta3 1.png" alt="icon" />
                        </div>
                        <span className="att_down" id="invested">
                            {incomesData.invested}
                        </span>
                    </div>
                    <div className="dividends">
                        <div className="att_up">
                            <span>Заработок</span>
                            <img src="img/Moneta3 1.png" alt="icon" />
                        </div>
                        <span className="att_down" id="incomes">{incomesData.incomes}</span>
                        <span className="att_down_hour">в час</span>
                    </div>
                </div>
                <ul id="all_or_mine">
                    <li onClick={()=>{setActiveOwnership("Все");}} className={"Все" === activeOwnership ? "earn-act" : ''}><p>Все</p></li>
                    <li onClick={()=>{setActiveOwnership("Мои");}} className={"Мои" === activeOwnership ? "earn-act" : ''}><p>Мои</p></li>
                </ul>
                <ul id="categories">
                    {[...groups].map((group, i) => (
                        <li
                            key={i}
                            onClick={() => {if (activeGroup === group) {setActiveGroup("");} else {setActiveGroup(group);}}}
                            className={group === activeGroup ? "earn-act" : ''}
                        >
                            {group}
                        </li>
                    ))}
                </ul>

                <div className="scroll-container" id="scroll-content-address">
                    <div className="scroll-content">
                        {loading ? (
                            <p>Loading...</p>
                        ) : (
                            req_info
                                .filter((obj) => (obj.group === activeGroup || activeGroup === "") && (activeOwnership === "Все" || obj._id in incomesData))
                                .map((obj, index) => (
                                    <div className="item" key={index}>
                                        <img src={obj.img_url} alt={obj.title} />
                                        <hr />
                                        <div className="item-content">
                                            <div className="text-item">
                                                <span className="item-name">{obj.title}</span>
                                                <span className="virtual">{obj.description}</span>
                                            </div>
                                            <div className="verticalLine"></div>
                                            <BuyButton
                                                text="Buy"
                                                data={obj}
                                                tg={tg}
                                                handleBuyClick={handleBuyClick}
                                                incomesData={incomesData[obj._id]}
                                            />
                                        </div>
                                    </div>
                                ))
                        )}
                    </div>
                </div>
                {buyComponentData && (
                    <div className="buy_back-layout">
                        <button id="buy_overlay_close" onClick={handleCloseBuyComponent}>
                            <img src="/img/cross.svg" alt="cross"/>
                        </button>
                        <div id="buycomponent">
                            <BuyComponent data={buyComponentData} user={data} pan={setSlide} sli={slide}
                                          onStateBuyChange={handleBuyStateChange}
                                          incomesData={incomesData[buyComponentData._id]} symbol={buyComponentData.title} balanceUpdater={balanceUpdater}/>
                            <div id="slide_wrapper">
                                <button className="slide_button" onClick={()=> {
                                    setSlide(slide > 1 ? slide-1 : 2)
                                }}><img src="/img/arrow_left.png" alt=""/></button>
                                <img src={slide === 1 ? "/img/slider_left_two.svg" : '/img/slider_right_two.svg'}/>
                                <button className="slide_button" onClick={()=> {
                                    setSlide(slide < 2 ? slide+1 : 1)
                                }}><img src="/img/arrow_right.png" alt=""/></button>
                            </div>
                        </div>
                    </div>
                )}
            </div>

        </div>
    );
}

export default Earnings;
