import { memo, useEffect, useState } from "react";

const ToDoList = memo(() => {
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getTasks() {   
            try {
                const req = await fetch(`https://api.spin2win.tech/GetTasks/${window.Telegram.WebApp.initDataUnsafe?.user?.id}`, {
                    method: 'GET',
                    headers: {
                        'Check-String': window.Telegram.WebApp.initData ? window.Telegram.WebApp.initData : ""
                    }
                });
                if (req.ok) {
                    const res = await req.json();
                    setTasks(res);
                } else {
                    console.error("Failed to fetch data");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        }

        getTasks();
    }, []);

    async function Claim(_id, img_url, title, description, cost, completed) {
        try {
            const req = await fetch(`https://api.spin2win.tech/ClaimTask/${window.Telegram.WebApp.initDataUnsafe?.user?.id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Не забудьте добавить этот заголовок
                    'Check-String': window.Telegram.WebApp.initData ? window.Telegram.WebApp.initData : ""
                },
                body: JSON.stringify({
                    "_id": _id,
                    "img_url": img_url,
                    "title": title,
                    "description": description,
                    "cost": cost,
                    "completed": completed
                })
            });

            if (req.ok) {
                const res = await req.json();
                if (res.status.toLowerCase() === "success") {
                    // Обновление состояния задач
                    setTasks((prevTasks) =>
                        prevTasks.map(task =>
                            task._id === _id ? { ...task, claimed: true } : task
                        )
                    );
                }
            } else {
                console.error("Failed to fetch data");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    return (
        <div className="layout_td">
            <img className="td_img" src="img/Bitecoin2 1.png" alt="Bitecoin" />
            <div className="lay_td">
                {!loading && tasks.length !== 0 && tasks.map((task) => (
                    <a className="task" key={task._id} style={{display: task.completed === 'completed'? 'none' : 'grid', textDecoration: "none"}} href={task.link}>
                        <img src={task.img_url} className="task_image" alt={task.title} />
                        <div className="task_text">
                            <span className="task_title">{task.title}</span>
                            <span className="task_description">{task.description}</span>
                            <div className="task_price">
                                <img className="mny" src="/img/Moneta3 1.png" alt="Money" />
                                <span>+ {task.cost}</span>
                            </div>
                        </div>
                        <button 
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                Claim(task._id, task.img_url, task.title, task.description, task.cost, task.completed);
                            }}
                            id={`btn-${task._id}`}
                        >
                            {task.claimed || task.completed ? 'Done' : 'Claim'} {/* Используем состояние для отображения текста */}
                        </button>
                    </a>
                ))}
            </div>
        </div>
    );
});

export default ToDoList;
