import { memo } from "react";

const ProgresBar = memo((props) => {

    const incomeLevels = {
        "Новичок": 1,
        "Ученик": 2,
        "Инвестор": 3,
        "Трейдер": 4,
        "Стартапер": 5,
        "Гуру": 6,
        "Криптомиллионер": 7,
        "Элита": 8,
        "Форбс": 9,
        "Титан": 10
    };


    return (
        <div className="progress_panel">
            <div className="progress_text">
                <span className="league">League {props.data.league ? props.data.league : "Новичок"}</span>
                <span className="level">Level {incomeLevels[props.data.league ? props.data.league : "Новичок"]}</span>
            </div>
            <div className="pr_lay">
                <progress id="file" max="100" value={props.data.percent ? props.data.percent : 0}></progress>
            </div>
            
        </div>
        

)});

export default ProgresBar;