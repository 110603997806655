import {memo} from "react"

const Money_info = memo((props) => {
    return (
    <div className="money_info">
        <img src="/img/Moneta3 1.png" srcSet="/img/Moneta3 1_2x.png 2x"/>
        <span className="balance" id="balance">{props.data.balance}</span>
    </div>
)});

export default Money_info